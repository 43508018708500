@import "styles/global";

.popupBackdrop {
  width: 100%;
  height: 100%;
  animation: fade-in 200ms forwards;

  @keyframes fade-in {
    from { background: transparent; }
    to   { background: rgba(0, 0, 0, 0.2); }
  }
}

.popupWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  min-width: 30%;
  max-width: 90%;
  max-height: 90%;
  position: relative;
  background-color: $primary-white;
  border: 1px solid $secondary-gray-4;
  border-radius: 5px;
  box-shadow: 0 0.3rem 1rem rgba(0, 0, 0, 0.08);
  overflow-x: hidden;
  overflow-y: scroll;
  @include custom-scrollbar-light;

  transform: scale(0);
  animation: enter 200ms forwards;
  @keyframes enter {
    from { transform: scale(0); }
    to   { transform: none; }
  }
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
}

.content {
  height: 100%;
  padding: 7.5rem 5rem 5rem 5rem;
}
