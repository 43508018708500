@import "styles/global";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  .title {
    font-size: 3.5rem;
    &:after {
        display: block;
        content: '';
        width: 14rem;
        height: 90%;
        right: 0;
        background-image: url("./stars.svg");
        background-size: 90%;
        background-position: bottom right;
        background-repeat: no-repeat;
      }
  }

  .description {
    padding-top: 3rem;
    width: 50rem;
    font-size: 2.75rem;
    text-align: center;
  }
}
