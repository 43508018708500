@import "src/styles/global";

.container {
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  padding: 4.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  :global(.carousel .control-dots) {
    display: flex;
    list-style-type: none;
    position: absolute;
    bottom: -8rem;
    z-index: 100;
    left: 3rem;
    justify-content: center;
    width: 100%;
    text-align: center;
    max-width: unset;
  }

  :global(.carousel.carousel-slider) {
    overflow: visible;
    padding: 0;
    margin: 0 auto;
  }

  :global(.carousel .slider) {
    margin: 0 auto;
    padding: 0;
    width: 45%;
  }

  :global(.slider .slide) {
    margin: 0;
    align-items: center;
  }

  :global(.carousel.carousel-slider .control-arrow) {
    z-index: 1000;
    background: #ffffff;
    opacity: .6;
    cursor: default;
    width: 1px;
    padding: 0;

    &:hover, &:active, &:focus {
      opacity: 1;
      transform: scale(1.2);
    }

    &::before {
      transform: translate(-35%, -50%) rotate(-90deg);
      position: absolute;
      left: 50%;
      top: 50%;
      height: 5rem;
      width: 5rem;
      border: none;
      background: url("~assets/images/arrow-down.svg") no-repeat center;
      background-size: cover;
      cursor: pointer;
    }
  }

  :global(.carousel .control-prev.control-arrow:before) {
    transform: translate(-90%, -50%) rotate(90deg);
  }

  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5rem;
  }

  .sideButtons > * {
    margin-left: 1rem;
  }

  p, span {
    @include section-subheader;
  }
}

.itemWrapper {
  width: 42rem;
  height: 29rem;
  display: flex;
  justify-content: center;
  position: relative;
  pointer-events: none;


  &.itemWrapper.onlyNextGame .item {
    &.active {
      pointer-events: all;
      cursor: pointer;
    }
    &.upcoming {
      opacity: .55;
    }
  }

  &.itemWrapper.allUpcomingGames .item {
    &.active, &.upcoming {
      pointer-events: all;
      cursor: pointer;
    }
  }

  .tag {
    padding: 0.3rem 1.5rem;
    background: $primary-dark-blue;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    position: absolute;
    top: 10%;
    left: 15%;
    color: #FFFFFF;
    z-index: 2;
    font-weight: 600;
  }

  &.itemWrapper.onlyNextGame .tag.upcoming {
    background-color: #888888;
  }

  &.onlyNextGame .item {
    &.active {
      cursor: pointer;
    }

    &.upcoming {
      opacity: .55;
    }
  }

  .item {
    width: 36rem;
    height: 28.6rem;
    box-sizing: border-box;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #BDBDBD;
    transition: .3s ease-in-out;
    transform-origin: left;
    background: #FFFFFF url("~assets/images/correct.svg") no-repeat center;
    background-size: 0;
    position: relative;

    .finished {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #FFFFFF url("~assets/images/correct.svg") no-repeat center;
      background-size: 0;
      opacity: 0;
      transition: .3s ease-in-out;

      &.visible {
        background-size: 20%;
        opacity: 1;
      }
    }

    .gameImg {
      height: 18rem;
      width: 100%;
      background: url("img/unknown.svg") no-repeat center;
      background-size: contain;
    }

    .corsi {
      background-image: url("img/corsi.svg");
    }

    .tmt {
      background-image: url("img/tmt.svg");
    }

    .digit-span {
      background-image: url("img/digit-span.svg");
    }

    .bret {
      background-image: url("img/bret.svg");
    }

    .cpt {
      background-image: url("img/cpt.svg");
    }

    .mtpt {
      background-image: url("img/mtpt.svg");
    }

    .big-five-quizz {
      background-image: url("img/big-five-quizz.svg");
    }

    .aboutGame {
      border-top: 1px solid #BDBDBD;
      text-align: left;
      padding: 1.5rem 3rem;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }

    .aboutGameRow {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .superPower {
      display: flex;
      align-items: center;
    }

    .star {
      display: flex;
      width: 2.4rem;
      height: 2.4rem;
      min-width: 2.4rem;
      min-height: 2.4rem;
      background: url("~assets/images/star.svg") no-repeat center;
      background-size: cover;
      margin-right: 1.5rem;
    }

    .description {
      display: none;
    }
  }
}

.carouselWrapper {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 8%;
}

.indicator {
  width: 2.25rem;
  height: 2.25rem;
  background: #FFFFFF;
  border: 1px solid #828282;
  border-radius: 50%;
  margin: 0 1.5rem;
  cursor: pointer;
  transition: .2s ease-in-out;
  outline: none;

  &::marker {
    display: none;
  }

  &.finished {
    background: url("~assets/images/correct.svg") no-repeat center;
    background-size: 98%;
    border: none;
  }

  &.selected {
    border-width: 2px;
  }

  &:active, &:focus, &:hover {
    transform: scale(1.1);
    border-width: 2px;
  }

  &.active {
    background: #4F4F4F;
    border-color: #4F4F4F;
  }
}

.background {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 105%;
  height: 110%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 1) 95%);
  user-select: none;
  pointer-events: none;
}

@media (min-width: $desktop-width) {
  .itemWrapper {
    .tag {
      top: 8%;
      left: 12%;
    }

    .item {
      .gameImg {
        height: 15rem;
      }

      .aboutGameRow {
        flex-direction: row;
      }

      .gameName {
        font-size: 2rem;
        line-height: 4rem;
      }

      .aboutGame {
        height: 13.6rem;
      }

      .superPower {
        max-width: 60%;
      }

      .star {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1rem;
      }

      .description {
        display: block;
      }
    }
  }

  .container {
    p, span {
      font-size: 1.3rem;
      line-height: 2rem;
    }
  }
}

@media (max-width: $desktop-width) {
  p.superPowerText {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
