
@mixin custom-scrollbar {
  /* width */
  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $secondary-gray-6;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $primary-dark-blue;
    border-radius: 3px;
  }
}

@mixin custom-scrollbar-light {
  /* width */
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $primary-white;
    border-radius: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $secondary-gray-5;
    border-radius: 5px;
  }
}

@mixin section-subheader {
  font-size: 2.1rem;
  line-height: 3.3rem;
}

@mixin for-desktop {
  @media (min-width: 1000px) {
    @content;
  }
}

@mixin reset-unordered-list {
  margin:0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
}
