@import "src/styles/global";

.wrapper {
  position: relative;
  height: 100%;
}

.activeArea {
  max-height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  // background: rgba(255,0,0,0.05 );
}

.cursorArea {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.svg {
  width: 100%;
  height: 100%;
}

.progressShape {
  opacity: 0;
}

.mainShape {
  stroke: $primary-black;
  fill: $primary-white;
  stroke-width: 5;
  animation: none;
  transform: translate(0, 0) rotate(0);

  @keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1.5deg); }
    20% { transform: translate(-3px, 0px) rotate(1.2deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1.2deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1.5deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1.2deg); }
  }

  @keyframes blink {
    0% { fill: #fff; }
    10% { fill: #E53535; stroke: #E53535; }
    20% { fill: #E53535; stroke: #E53535; }
    30% { fill: #fff; stroke: #333333; }
    40% { fill: #E53535; stroke: #E53535; }
    50% { fill: #fff; stroke: #333333; }
    60% { fill: #E53535; stroke: #E53535; }
    70% { fill: #fff; stroke: #333333; }
    80% { fill: #E53535; stroke: #E53535; }
    90% { fill: #fff; stroke: #333333; }
    100% { fill: #E53535; stroke: #E53535; }
  }

  @keyframes mark-complete {
    0%   { fill: #fff; }
    100% { fill: #78a2ff; stroke: #353f97; }
  }

  .isFinished & {
    animation: mark-complete 1s;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .hasError &{
    animation: shake 0.5s, blink 1s;
    animation-iteration-count: infinite;
  }
}

.cursor {
  position: absolute;
  border-radius: 50%;
  background: $primary-orange;
  width: 2.2rem;
  height: 2.2rem;
  transform: translate(-50%, -50%);
  top: 0;
  left: 0;
  animation: pulse 1s infinite 0.1s;
  transition: opacity 200ms;

  @keyframes pulse {
    0%   { transform: translate(-50%, -50%) scale(1.6)}
    50%  { transform: translate(-50%, -50%) scale(1.4)}
    100% { transform: translate(-50%, -50%) scale(1.6)}
  }

  @keyframes pulse-small {
    0%   { transform: translate(-50%, -50%) scale(1.2)}
    50%  { transform: translate(-50%, -50%) scale(1)}
    100% { transform: translate(-50%, -50%) scale(1.2)}
  }

  &.tracingMode {
    animation: pulse-small 1s infinite;
  }
}

.trace {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 1s;
  &.isCompleted {
    opacity: 0;
  }
}

.tooSlowNotification {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;

  & .text {
    display: block;
    font-size: 10rem;
    background: rgba(255, 255, 255, 0.75);
    border: 0.3rem solid $primary-black;
    padding: 0 5rem;
    border-radius: 2rem;
    color: $primary-red;
    animation: fade-out 1s 1s;
    animation-fill-mode: forwards;
  }

  @keyframes fade-out {
    0%   { opacity: 1; }
    100% { opacity: 0; }
  }
}

.quitButton {
  position: absolute;
  bottom: 3%;
  right: 3%;
}
