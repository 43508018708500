@import "styles/global";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  .gameTitle {
    font-size: 3.5rem;
  }

  .description {
    width: 50rem;
    font-size: 2.75rem;
  }

  .superPower {
    p {
      text-align: center;
      font-size: 2.5rem;
    }
  }
}
