@import 'styles/global';

@keyframes rollout {
  0% {
    opacity: 0;
    transform: translateY(-30%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.Rollout {
  animation: rollout 0.6s;
}

.Container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .CountDown {
    font-size: 10rem;
  }

  .DigitDisplay {
    width: 40rem;
    height: 40rem;
    border-radius: $border-radius-percent;
    background-color: $hero-color;
    display: flex;
    justify-content: center;
    align-items: center;

    .Digit {
      margin: 4rem;
      font-size: 20rem;
      color: $primary-white;
    }
  }

  .DigitDisplayReversed {
    background-color: $secondary-dark-green;
  }

  .GuessBoard {
    display: grid;
    grid-template-columns: 60% 40%;
    grid-template-rows: 1fr auto;
    grid-template-areas:
            "guess input"
            "submit submit";
    justify-items: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .LeftContainer {
      grid-area: guess;
      box-sizing: border-box;
      width: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin: 0 auto;
      .AmountOfDigitsContainer {
        width: 15rem;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid $primary-black;
        margin: 1rem auto;
        .AmountOfDigits {
          font-size: 2.3rem;
          font-weight: bold;
        }
      }
      .DirectionTip {
        font-size: 2rem;
      }
      .DirectionTipHighlight {
        font-weight: bold;
      }
      .GuessContainer {
        box-sizing: content-box;
        width: 50rem;
        height: auto;
        min-height: 6rem;
        padding: 2rem;
        border: 1px solid $primary-black;
        border-radius: 10px;
        .ResultGuess {
          width: 100%;
          font-size: 5rem;
          text-align: center;
          word-wrap: break-word;
        }
      }
    }
    .RightContainer {
      grid-area: input;
      width: 30rem;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      .DigitButton {
        width: 8rem;
        height: 8rem;
        margin: 0.5rem;
        padding: 0;
        font-size: 3rem;

        &:active {
          transform: translateY(4px);
        }
      }
      .ReduceAnswerButton {
        box-sizing: border-box;
        cursor: pointer;
        background: #FFFFFF;
        border-radius: 10px;
        border: 1px solid $primary-red;
        box-shadow: none;
        color: $primary-red;
        font-weight: 600;
        letter-spacing: 0.02em;
        -webkit-tap-highlight-color: transparent;
        display: inline-block;
        padding: 1.5rem 2.75rem 1.5rem 2rem;
        text-decoration: none;
        font-family: "Montserrat", sans-serif;
        font-size: 2.1rem;
        line-height: 3.3rem;
        text-align: center;
        transition: all 250ms ease-in-out,
        transform 150ms ease;
        -webkit-appearance: none;
        -moz-appearance: none;
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
        outline: none;
        position: relative;

        &:hover,
        &:focus,
        &:active {
          box-shadow: 0 4px 9px rgba(0, 79, 196, 0.2);
        }
        width: 17rem;
        height: 8rem;
        margin: 0.5rem;
        padding-left: 4rem;

        &:active {
          transform: translateY(4px);
        }

        &:after {
          position: absolute;
          content: '';
          display: block;
          left: 2.5rem;
          top: 50%;
          transform: translateY(-50%);
          width: 1.5rem;
          height: 1.5rem;
          background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='78.86mm' height='67.763mm' version='1.1' viewBox='0 0 78.86 67.763' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(-53.311 -114.62)'%3E%3Cg%3E%3Cpath d='m60.397 142.89v11.223h69.308c1.3664 0 2.4663-1.0523 2.4663-2.3595v-6.504c0-1.3072-1.0999-2.3595-2.4663-2.3595h-6.8895z' stroke-width='.19878' stroke='%23E53535' fill='%23E53535'/%3E%3Cpath d='m54.005 146.86c-0.92435 0.92435-0.92435 2.4126 0 3.3369l2.2352 2.2352-3.65e-4 3.6e-4 29.254 29.254c0.92435 0.92435 2.4126 0.92435 3.3369 0l4.599-4.599c0.92435-0.92435 0.92435-2.4126 0-3.3369l-4.599-4.599-20.622-20.623 25.283-25.283c0.92435-0.92435 0.92435-2.4126 0-3.3369l-4.599-4.599c-0.92435-0.92435-2.4125-0.92435-3.3369 0l-26.89 26.89-1.4901 1.4901z' stroke-width='.26458' stroke='%23E53535' fill='%23E53535'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
    }
    .BottomContainer {
      grid-area: submit;
      .SubmitButton {
        margin: 0 2rem 2rem;
      }
    }
  }
  .PauseDot {
    width: 50rem;
    height: 50rem;
    background: red;
    border-radius: 50%;
  }
}

.skipButton {
  top: 3%;
  left: 3%;
}
