.nextBtn {
  padding: 1.95rem 4.8rem;
  position: absolute;
  bottom: 3%;
  right: 3%;
}

.gameHeader {
  position: absolute;
  text-align: center;
  pointer-events: none;
  top: 1rem;
  width: 100rem;
  font-size: 3rem;
  line-height: 5rem;
  font-weight: bold;
}

.skipButton {
  bottom: 3%;
  left: 3%;
}