@import "styles/global";

.modalContainer {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  transition: .3s ease-in-out;
  pointer-events: none;
  z-index: 300;

  button {
    pointer-events: none;
  }

  &.visible {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;

    button {
      pointer-events: auto;
    }
  }

  .modal {
    width: fit-content;
    min-width: 45rem;
    min-height: 25rem;
    height: fit-content;
    background-color: #FFFFFF;
    filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.1));
    backdrop-filter: blur(4px);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3rem;

    &.gameOver {
      flex-direction: column;
      width: 32rem;
      min-width: 32rem;
      height: 45.2rem;
      text-align: center;
      justify-content: space-between;

      img {
        height: 12.7rem;
        width: 6.67rem;
      }

      p {
        max-width: unset;
      }
    }

    p {
      margin-top: 1.2rem;
      font-size: 2.1rem;
      line-height: 3.3rem;
      max-width: 25rem;
    }

    .modalImg {
      max-width: 15.7rem;
      height: 13rem;
    }
  }

  .cancelBtn {
    position: absolute;
    bottom: 3%;
    left: 3%;
  }

  .confirmBtn {
    color: $primary-white;
    background-color: $primary-dark-blue;
    position: absolute;
    bottom: 3%;
    right: 3%;
  }

  .cornerBtn {
    position: absolute;
    bottom: 3%;
    right: 3%;
  }

  .gameOverBtn {
    position: unset;
    margin-top: 2rem;
  }

  .feedbackIcon {
    margin-bottom: 1.8rem;
  }
}

.feedbackToast {
  padding: 3.3rem;
  background-color: #FFFFFF;
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.feedbackIcon {
  height: 6rem;
  width: 6rem;
  background: url("/assets/images/incorrect.svg") no-repeat left center;
  background-size: contain;
  margin-right: 3.3rem;

  &.success {
    background-image: url("/assets/images/correct.svg");
  }

  &.correct {
    background-image: url("/assets/images/correct.svg");
  }

  &.time-out {
    background-image: url("/assets/images/time-out.svg");
  }

  &.go {
    display: none;
  }
}
