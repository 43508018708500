@import "styles/global";

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.dictionary {
  position: absolute;
  width: 100%;
  height: 100%;
  background: $primary-white;
  z-index: 10;
  list-style: none;
  margin: 0;
  padding: 2rem 0 0 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  opacity: 0;
  transition: opacity 200ms;

  &.open {
    pointer-events: all;
    opacity: 1;
  }
}

.dictionaryItem {
  display: flex;
  flex-direction: column;
  padding: 1rem 0 1rem 5rem;

  & .word {
    display: block;
    font-weight: bold;
    font-style: italic;
    font-size: 3rem;
  }

  & .synonym {
    display: block;
    color: $secondary-gray-3;
    font-style: italic;
    font-size: 2rem;
    margin: 0 0 0 1rem;
  }

  & .example {
    display: block;
    margin: 0 0 0 1rem;
    font-size: 2rem;
    & p {
      font-size: 2rem;
    }
  }
}

.dictionaryButton {
  position: absolute;
  z-index: 20;
  bottom: 2rem;
  left: 2rem;
}

.nextButton {
  position: absolute;
  z-index: 20;
  bottom: 2rem;
  right: 2rem;
}

.image {
  position: absolute;
  width: 80rem;
  height: 25rem;
  top: 15rem;
  left: 50%;
  transform: translateX(-50%);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.selectableWord {
  display: block;
  position: absolute;
  font-size: 3rem;
  font-weight: bold;
  padding: 2.5rem;
  cursor: pointer;

  &.word-1 {
    top: 6rem;
    left: 5rem;
  }

  &.word-2 {
    top: 6rem;
    right: 5rem;
  }

  &.word-3 {
    top: 40rem;
    left: 5rem;
  }

  &.word-4 {
    top: 40rem;
    right: 5rem;
  }
  
  &.active {
    color: $primary-dark-blue;
  }
}

.trialNumber {
  font-size: 3rem;
  display: block;
  text-align: center;
  line-height: 6rem;
}

.skipButton {
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
}