
.container {
  width: 100%;
  height: 40rem;
  background: transparent !important;
  display: flex;
  align-items: center;

  .image {
    height: 100%;
    width: 100%;
    object-fit: contain;
    overflow: hidden;
  }

  .text {
    width: 100%;
    padding: 1rem;
    border-radius: 5px;
    * {
      text-align: center;
      font-size: 2.5rem;
    }
    text-align: center;
    font-size: 2.5rem;
  }
}