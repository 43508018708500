.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4.5rem;

  .row {
    display: flex;

    &:last-of-type {
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }

  .text p {
    font-size: 2.09rem;

    &:first-of-type {
      font-size: 2.7rem;
      line-height: 3.3rem;
      font-weight: bold;
      margin-bottom: 6px;
    }
  }

  .btn {
    position: unset;
    align-self: center;
  }

  .gameName {
    font-size: 3.6rem;
    margin: 0;
  }

  .playGameImg {
    align-self: flex-start;
    width: 33.8rem;
    height: 28.1rem;
  }
}
