@import "styles/global";

.card {
  border: 1px solid $secondary-gray-4;
  background: $primary-white;
  border-radius: 5px;
  padding: 24px;
  display: inline-block;
  vertical-align: top;

  width: 75rem;
  margin: 1.25rem 2.5rem;

  @include for-desktop {
    width: 24rem;
  }
}

.icon {
  display: block;
  width: 48px;
  height: 48px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.card.emotionalIntelligence {
  border-color: #548235;
  & .icon {
    background-image: url("./icons/emotional-intelligence.svg");
  }
}

.card.cognitive {
  border-color: #c55a11;
  & .icon {
    background-image: url("./icons/cognitive-skills.svg");
  }
}

.card.personality {
  border-color: #2f5597;
  & .icon {
    background-image: url("./icons/personality.svg");
  }
}

.card.behavior {
  border-color: #bf9000;
  & .icon {
    background-image: url("./icons/behavior.svg");
  }
}

.title {
  font-size: 24px;
  line-height: 36px;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 12px;
}

.subtitle {
  font-size: 16px;
  font-weight: normal;
  display: block;
  width: 100%;
  margin: 12px 0;
}

.description {
  font-size: 14px;
  line-height: 24px;
  margin: 12px 0;
  text-align: left;
}
