.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4.5rem;

  .row {
    display: flex;
    position: relative;

    &:last-of-type {
      width: 100%;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
    }
  }

  .score {
    font-size: 2.1rem;
    line-height: 3.3rem;
    align-self: flex-start;
  }

  .gameName {
    font-size: 3.6rem;
    margin: 0;
  }

  .resultWrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .gameOver {
      font-size: 5rem;
    }

    .result {
      display: block;
      font-size: 3rem;
      font-weight: 700;
      text-align: center;
      margin-top: 3rem;
    }

    .image {
      width: 20rem;
      margin-bottom: 2.5rem;
    }
  }

  .btn {
    position: unset;
    align-self: center;
  }

  .summaryImg {
    position: absolute;
    top: 0;
    right: 0;
    height: 29.4rem;
    width: 21.9rem;
    background: url("~assets/images/summaryImg.svg") no-repeat center;
    background-size: contain;
  }

  p {
    font-size: 2.1rem;
    line-height: 3.3rem;
  }
}
