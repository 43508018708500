@import "styles/global";


.modalContainer {
  width: 100%;
  height: 100%;
  animation: fade-in 200ms forwards;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  pointer-events: none;
  z-index: 300;

  @keyframes fade-in {
    from { background: transparent; }
    to   { background: rgba(0, 0, 0, 0.2); }
  }

  //button {
  //  pointer-events: none;
  //}

  //&.visible {
  //  opacity: 1;
  //  visibility: visible;
  //  pointer-events: auto;
  //
  //  button {
  //    pointer-events: auto;
  //  }
  //}

  .modal {
    width: 55rem;
    min-height: 25rem;
    height: fit-content;
    background-color: #FFFFFF;
    filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.1));
    backdrop-filter: blur(4px);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 3rem;

    & > p {
      max-width: 90%;
      margin: 1.2rem 0 0;
      font-size: 1.5rem;
      line-height: 2.6rem;
      white-space: pre-line;
    }
  }

  .cornerBtn {
    position: absolute;
    bottom: 3%;
    right: 3%;
  }
}
