@import "src/styles/global";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.items {
  flex: 1;
  display: flex;
  flex-direction: column;
}
