@import 'styles/global';

.tag {
  background: $secondary-blue;
  font-size: 14px;
  line-height: 32px;
  color: $primary-white;
  display: inline-block;
  padding: 0 10px;
  border-radius: 16px;
  white-space: nowrap;
}
