.wrapper {
  position: relative;
}

.innerWrapper {
  position: absolute;
  top: 0;
  width: 100%;
}

.innerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  form {
    width: 100%;
    padding: 0 32px 32px 32px;
  }

  :global(.MuiFormLabel-root) {
    margin: 32px 0 16px 0;
    color: black;
  }

  :global(.MuiFormControl-root) {
    // display: flex;
    // width: 100%;
    // margin: 32px 0;
  }

}
