@import "src/styles/global";

.skipButton {
  left: 50%;
  transform: translateX(-50%);
}

.buttons {
  display: flex;
  justify-content: space-between;
  padding: 0 5rem;
  margin-bottom: 2rem;
}

.items {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (orientation: portrait) {
  .buttons {
    margin-bottom: 6rem;

    button {
      font-size: 3rem;
      padding: 2rem 2.75rem 2rem 7.85rem;
    }
  }
}
