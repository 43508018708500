@import "src/styles/global";

.languageBtn {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2.25rem;
  border: 1px solid #E0E0E0;
  background-color: #FFFFFF;
  box-sizing: border-box;
  border-radius: 1.5rem;
  width: 10.5rem;
  height: 5.4rem;
  margin-right: 4.5rem;
  outline: none;

  img {
    width: 2.4rem;
  }

  .languageOptions {
    position: absolute;
    left: 0;
    top: 80%;
    margin: 0 -1px;
    width: calc(100% + 2px);
    display: flex;
    padding: 0;
    flex-flow: column;
    padding: 0 2.25rem;
    border: 1px solid #E0E0E0;
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 1.5rem;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-top: none;
    z-index: 1000;

    button {
      background: none;
      border: none;
      padding: 0.5em 0;
    }
  }
}


