@import "src/styles/global";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  padding: 10em;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  background: linear-gradient(#ffffff 0%, rgba(0, 79, 196, 0.43) 100%), url("noisy-bg.png");

  .loginImg {
    position: absolute;
    top: 0;
    left: 2%;
    width: 40rem;
    height: 100%;
    background: url("jobs.svg") no-repeat center;
    background-size: contain;
    display: none;
    text-align: center;
    font-size: 50px;
    padding-top: 30px;
    font-weight: 700;
  }

  .loginForm {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 4.5em;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    box-shadow: 0 1em 2em rgba(0, 79, 196, 0.26);
    border-radius: 10px;
    position: relative;
    width: 55rem;
    min-width: 460px;
    max-width: 600px;
    min-height: 40rem;
    max-height: 750px;

    .row {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin-bottom: 4em;
    }

    h4 {
      font-size: 4.5em;
    }

    .loginIcon {
      position: absolute;
      top: 0;
      right: -2%;
      transform: translate(0, -50%);
      min-width: 20em;
      min-height: 20em;
      background: url("loginImg.svg") no-repeat center;
      background-size: contain;
    }

    :global(.MuiFormLabel-root), :global(.MuiInputBase-root) {
      font-size: 2.1em;
      font-family: "Montserrat", sans-serif;
    }

    :global(.MuiInputLabel-outlined.MuiInputLabel-shrink) {
      font-size: 1.7em;
    }

    :global(.MuiFormHelperText-root) {
      font-size: 1.2em;
      font-family: "Montserrat", sans-serif;
    }

    .formInputs {
      width: 100%;
      height: 37em;
      min-height: 350px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .field {
      width: 100%;
      height: 9.5em;
      min-height: 90px;
      min-width: 350px;
    }

    .requiredInfo {
      display: block;
      margin: 3rem auto 0 0;
      text-align: center;
      line-height: 2em;
      font-size: 1.4em;
    }

    .icon {
      height: 1.5em;
      width: 1.5em;
      background: url("~assets/images/cancel-red.svg") no-repeat center;
      background-size: contain;
      opacity: 0;
      transition: .3s ease-in-out;

      &.visible {
        opacity: 1;
      }

      &.success {
        background-image: url("~assets/images/check.svg");
      }
    }

    .loginBtn {
      background: #004FC4;
      box-shadow: none;
      color: white;
      padding: 0.8em 1.8em;
      font-size: 2.1em;
      margin-top: 0.5em;

      &:hover, &:active, &:focus {
        box-shadow: 0 4px 9px rgba(0, 79, 196, 0.25);
      }

      &.disabled {
        opacity: .6;
        cursor: default;
        pointer-events: none;
      }
    }

    .reminderText {
      font-size: 2.5em;
      color: $primary-black;
    }

    :global(.MuiCheckbox-colorPrimary.Mui-checked) {
      color: #004FC4;
    }

    :global(.MuiSvgIcon-root) {
      width: 2.1em;
      height: 2.1em;
    }

    .label {
      height: 6em;
      min-height: 70px;
      margin: 10px 0 0;
    }

    .consentLabel {
      margin-left: 1em;
      font-size: 1.8em;
      font-family: "Montserrat", sans-serif;
    }

    .link {
      color: rgba(0, 79, 196, 0.66);
      text-decoration: underline;
      transition: .2s ease-in-out;

      &:hover {
        color: rgba(0, 79, 196, 1);
      }
    }
  }
}

.error {
  color: $primary-red;

  :global(.MuiCheckbox-colorPrimary) {
    color: $primary-red;
  }
}

.consentModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: white;
  display: none;
  overflow: auto;

  &.open {
    display: block;
  }

  .consentBox {
    margin: 0 auto;
    width: 100%;
    padding: 5% 10vw;
    min-height: 60%;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  h4 {
    margin-bottom: 3rem;
  }

  .text {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    p {
      margin: 2rem 0;
      font-size: 2.1rem;
      line-height: 3.3rem;
    }
  }

  .closeBtn {
    margin: 5% 5% 5% auto;
    display: block;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 100px white inset !important;
}

@mixin mobileStyles {
  .container {
    padding: 100px 20px;

    .loginForm {
      min-height: 350px;
      min-width: 100%;
      padding: 40px 30px 30px;

      :global(.MuiFormLabel-root),
      :global(.MuiInputBase-root),
      :global(.MuiInputLabel-outlined.MuiInputLabel-shrink) {
        font-size: 16px;
      }

      :global(.MuiFormHelperText-root) {
        font-size: 12px;
        line-height: 14px;
      }

      .field {
        min-width: unset;
      }

      .formInputs {
        min-height: 350px;
      }

      .requiredInfo {
        margin: 20px auto 0 0;
        line-height: 14px;
        font-size: 11px;
      }

      .icon {
        height: 26px;
        width: 26px;
      }

      .row {
        margin-bottom: 40px;

        h4 {
          font-size: 30px;
          z-index: 3;
          line-height: 36px;
          background-color: #ffffff;
        }
      }

      .loginIcon {
        min-width: 150px;
        min-height: 150px;
      }

      .loginBtn {
        margin-top: 10px;
        padding: 20px 30px;
        font-size: 16px;
        line-height: 16px;
        background-position: 10px center;
      }

      .label {
        min-height: 80px;
      }

      :global(.MuiSvgIcon-root) {
        width: 24px;
        height: 24px;
      }

      .consentLabel {
        margin-left: 1em;
        font-size: 14px;
      }

      .reminderText {
        font-size: 3.5em;
      }
    }
  }

  .consentModal {

    .consentBox {
      width: 100%;
      padding: 30px;
    }

    h4 {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 20px;
    }

    .text p {
      margin: 20px 0;
      font-size: 16px;
      line-height: 24px;
    }

    .closeBtn {
      width: 40px;
      height: 40px;
      background-size: 20px;
    }
  }
}

@media (max-width: 768px) and (orientation: portrait) {
  @include mobileStyles;
}

@media (max-height: 768px) and (orientation: landscape) {
  @include mobileStyles;
}

@media (min-width: 500px) {
  .container .loginForm {
    min-width: 480px;
  }
}

@media (max-width: 320px) {
  .container {
    display: block;

    .loginForm {
      top: 50%;
      left: 50%;
      position: absolute;
      margin: 0 20px;
      transform: translate(-50%, -50%);
      min-width: 220px;
      width: 100%;

      .formInputs {
        height: unset;
      }

      .field {
        height: auto;
      }
    }
  }
}

@media (min-width: 1024px) {
  .container {
    background: #ffffff url("noisy-bg.png");
    padding: 0 8em 0 0;
    font-size: 9px;

    .formInputs {
      height: 30em;
      min-height: 250px;
    }

    .loginImg {
      display: block;
    }

    .loginForm {
      margin: auto 0 auto 50rem;
      width: 60em;
      min-height: unset;

      .loginIcon {
        display: none;
      }

      .row {
        text-align: center;
        justify-content: center;
      }

      .loginBtn {
        padding: 0.6em 1.8em;
        font-size: 2.2em;
        line-height: 2.1em;
      }

      :global(.MuiSvgIcon-root) {
        width: 30px;
        height: 30px;
      }

      .consentLabel {
        margin-left: 1em;
        font-size: 16px;
      }
    }
  }

  .consentModal {
    .consentBox {
      padding: 2vh 30px 0;
      width: 80%;
      max-width: 1000px;
      margin: 0 auto;
      justify-content: center;
    }

    h4 {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 20px;
    }

    .text p {
      margin: 20px 0;
      font-size: 20px;
      line-height: 30px;
    }

    .closeBtn {
      width: 50px;
      height: 50px;
      background-size: 25px;
      margin: 5% calc(calc(100vw - 950px) / 2) 5% auto;
    }
  }
}

