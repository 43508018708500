@import "src/styles/global";

.quizzItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 4rem;
}

.image {
  width: 100%;
  height: 25rem;
  margin-bottom: 2.5rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center
}

.question {
  font-size: 2.5rem;
  margin-bottom: 2rem;
}
