.timerWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  .timer {
    display: block;
    font-size: 3rem;
    margin-left: 3rem;
    margin-top: 2rem;
  }
}
