@import "styles/global";

.gameArea {
  background-color: #ADD8E6;
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: none;

  &.enableClick {
    pointer-events: all;
  }
}

.fish {
  position: absolute;
  transition: 0.3s;
  background: transparent url("./img/fish.svg") no-repeat center;
  background-size: 85%;
  width: 12rem;
  height: 12rem;
  border-radius: 1rem;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  &:nth-of-type(1) {
    top: 11%;
    left: 10%;
  }

  &:nth-of-type(2) {
    top: 77%;
    left: 25%;
  }

  &:nth-of-type(3) {
    top: 46%;
    left: 52%;
  }

  &:nth-of-type(4) {
    top: 58%;
    left: 82%;
  }

  &:nth-of-type(5) {
    top: 75%;
    left: 55%;
  }

  &:nth-of-type(6) {
    top: 28%;
    left: 75%;
  }

  &:nth-of-type(7) {
    top: 57%;
    left: 7%;
  }

  &:nth-of-type(8) {
    top: 8%;
    left: 52%;
  }

  &:nth-of-type(9) {
    top: 38%;
    left: 27%;
  }

  &.highlighted {
    background-color: rgba($primary-yellow, 1);
  }
}

.corsiBtn {
  padding: 1.95rem 4.8rem;
  position: absolute;
  bottom: 3%;
  right: 3%;
}

.feedback {
  position: absolute;
  width: 20rem;
  height: 10rem;
  bottom: 3%;
  right: 3%;
  border-radius: 10px;
  background: #ADD8E6 url("~assets/images/incorrect.svg") no-repeat bottom center;
  background-size: 0;
  transition: .2s ease-in-out;
  opacity: 0;
  visibility: hidden;

  &.success {
    background-image: url("~assets/images/correct.svg");
  }

  &.visible {
    opacity: 1;
    visibility: visible;
    background-size: 40%;
  }
}

.skipButton {
  bottom: 3%;
  left: 3%;
}