@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  src: url("~assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  src: url("~assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  src: url("~assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  font-style: normal;
  src: url("~assets/fonts/Montserrat-Light.ttf") format("truetype");
}

a {
  color: $primary-dark-blue;
  text-decoration: none;

  &:hover, &:active, &:focus {
    color: $primary-orange;
  }
}
