@import "styles/global";

.card {
  border: 1px solid $secondary-gray-4;
  background: $primary-white;
  border-radius: 5px;
  padding: 24px;
  display: inline-block;
  vertical-align: top;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;

  width: 75rem;
  margin: 1.25rem 2.5rem;

  @include for-desktop {
    width: 24rem;
  }
}

.topBar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon {
  margin: 0 10px 0 0;
  width: 50px;
  height: 32px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;

  &.R {
    background-image: url("../icons/riasec-r.svg");
  }
  &.I {
    background-image: url("../icons/riasec-i.svg");
  }
  &.A {
    background-image: url("../icons/riasec-a.svg");
  }
  &.S {
    background-image: url("../icons/riasec-s.svg");
  }
  &.E {
    background-image: url("../icons/riasec-e.svg");
  }
  &.C {
    background-image: url("../icons/riasec-c.svg");
  }
}

.name {
  font-size: 18px;
  line-height: 18px;
  margin: 24px 0;
}

.description {
  font-size: 14px;
  line-height: 24px;
}

.learnMoreContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leafIcon {
  width: 48px;
  height: auto;
}

.learnMore {
  display: inline-block;
  position: relative;
  text-decoration: none;
  font-size: 14px;
  line-height: 24px;
  padding: 24px 0;
  outline: none;

  color: $primary-dark-blue;

  &:visited {
    color: $primary-dark-blue;
  }

  &:focus, &:hover {
    text-decoration: underline;
    color: $primary-dark-blue;
  }

  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 6px;
    height: 12px;
    background-image:
    url("data:image/svg+xml;utf8,<svg width='6' height='12' viewBox='0 0 6 12' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0.5 11L5.5 6L0.5 1' stroke='%23004FC4' stroke-linecap='round' stroke-linejoin='round'/></svg>");
    background-size: contain;
    pointer-events: none;
    transform: translate(300%, -50%);
    transition: transform 300ms;
  }

  &:hover:after, &:focus:after {
    transform: translate(400%, -50%);
  }
}

.searchButton {
  cursor: pointer;
  color: $primary-dark-blue;
  font-size: 14px;
  line-height: 42px;
  height: 42px;
  padding: 0 24px;
  background: $primary-white;
  border: 1px solid $secondary-gray-4;
  border-radius: 10px;
  box-shadow: none;
  font-weight: 600;
  letter-spacing: 0.02em;
  display: inline-block;
  margin: 0;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  -webkit-tap-highlight-color: $secondary-dark-blue;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  transition: background 250ms, color 250ms, border-color 250ms;

  &:hover {
    background: $secondary-blue;
    color: $primary-white;
    border-color: $secondary-gray-3;

  }

  &:focus {
    border-color: $secondary-dark-blue;
    border-width: 2px;
    padding: 0 23px;
    line-height: 40px;
  }
}
