.notificationContainer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

  &.visible {
    opacity: 1;
    visibility: visible;
  }
}

