@import "styles/global";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;

  .title {
    font-size: 3.5rem;
    margin-bottom: 3rem;
    &:after {
    display: block;
    content: '';
    width: 14rem;
    margin: 0 auto;
    height: 90%;
    right: 0;
    background-image: url("./stars.svg");
    background-size: 70%;
    background-position: center center;
    background-repeat: no-repeat;
  }
  }

  .subTitle {
    font-size: 3rem;
    margin-top: 5rem;
    max-width: 80rem;
    text-align: center;
  }

  .descriptionSmall {
    font-size: 2rem;
    max-width: 80rem;
    text-align: center;
  }

  .subTitleSmall {
    margin-top: 2rem;
    font-size: 2rem;
    max-width: 80rem;
    text-align: center;
  }

  .description {
    width: 60rem;
    font-size: 2.75rem;
  }

  .labelsContainer {
    display: flex;
    justify-content: space-between;
    width: 80rem;
  }

  .skillDescription {
    font-size: 2.5rem;
    margin: 0;
  }

  .skillDescriptionSmall {
    font-size: 2rem;
    margin: 0;
  }

  .skillDescriptionExtra {
    width: 60rem;
    font-size: 2rem;
    font-weight: bold;
  }

  .skillBarContainer {
    width: 80rem;
    height: 3px;
    background-color: rgb(52, 104, 184);
    position: relative;
    margin: 10px auto;
  }

  .skillBarStrength {
    width: 15px;
    height: 10px;
    position: absolute;
    background-color: rgb(50, 103, 185);
    top: 50%;
    transform: translateY(-50%);
    border: 1px solid black;
  }
}
