@import 'styles/global';

.prompt {
  z-index: 100;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  opacity: 1;

  .phoneIcon {
    animation: rotating 2s 1s linear infinite;
  }
}

.phoneIcon {
  max-height: 50%;
  height: 100%;
  width: auto;
  transform: rotate(-90deg);
}

.closeIcon {
  cursor: pointer;
  display: block;
  border: none;
  background-color: transparent;
  position: absolute;
  top: 14px;
  right: 14px;
  height: 24px;
  width: 24px;
  padding: 4px;
  background-image: url("~assets/images/x.svg");
  background-size: 90%;
  background-position: center;
  background-repeat: no-repeat;
}

@keyframes rotating {
  70% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}

.Text {
  color: $primary-black;
  text-align: center;
}

.ShowDetails {
  margin-top: 20px;
  font-size: 18px;
  line-height: 32px;
}

.InstructionsWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.Instructions {
  background: #ffffff;
  height: 100%;
  padding: 100px 20px 20px 20px;
  overflow-y: scroll;
  p {
    max-width: 500px;
    font-size: 18px;
    line-height: 32px;
    margin: 0 auto 12px auto;
  }
  h1 {
    max-width: 500px;
    margin: 0 auto;
    font-size: 26px;
    font-weight: bold;
    text-align: left;
  }

  h2 {
    max-width: 500px;
    margin: 36px auto 22px auto;
    font-size: 18px;
    line-height: 18px;
    font-weight: bold;
    text-align: center;
    color: $primary-dark-blue;
  }
}

.ButtonArea {
  background: #ffffff;
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 20px;
  border-radius: 20px;
}

.Close {
    width: 40px !important;
    height: 40px !important;
    background-size: 20px !important;

}
