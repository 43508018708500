@import 'styles/global';

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.layout {
  position: absolute;
  top: 0;
  width: 100%;
  display: grid;
  background: #fafafa;

  grid-template-columns: 1fr;
  grid-template-areas:
    "topbar"
    "content"
    "footer";


  @include for-desktop {
    grid-template-columns: 1fr 70rem 1fr;
    grid-template-areas:
      "topbar topbar topbar"
      ". content ."
      "footer footer footer";
  }
}

.topbar {
  grid-area: topbar;
  background: $primary-white;
  border-bottom: 1px solid #f2f2f2;
  top: 0;
  position: sticky;
  z-index: 200;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0 2rem 0 0;
  box-shadow: 0 0.3rem 1rem rgba(0, 0, 0, 0.08);

  width: 100vw;
  height: $topbar-height;
}

.sidebar {
  grid-area: sidebar;
  display: none;
  @include for-desktop {
    display: block;
  }
}

.footer {
  grid-area: footer;
}

.content {
  grid-area: content;
  background-image: url("~assets/images/jobs.svg");
  background-size: 80%;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-color: #fff;

  width: 100vw;
  padding-bottom: 85rem;
  @include for-desktop {
    width: 70rem;
    padding-bottom: 70rem;
  }
}

.content .section {
  width: 100%;
  @include for-desktop {
    width: 70rem;
  }
}
