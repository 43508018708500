@import "styles/global";

.item {
  margin-top: 48px;
}

.name {
  font-size: 16px;
  line-height: 24px;
}

.description {
  font-size: 14px;
  line-height: 24px;
}

.barWrapper {
  margin: 14px 0;
  height: 24px;
  background: $secondary-gray-6;
}

.barValue {
  height: 100%;
  background: $primary-dark-blue;
  transform-origin: left;
  transform: scaleX(0);
  animation: grow 500ms 200ms ease-in forwards;
  @keyframes grow {
    from { transform: scaleX(0) }
    to   { transform: scaleX(1) }
  }
}

.link {
  cursor: pointer;
  display: inline-block;
  position: relative;
  text-decoration: none;
  font-size: 14px;
  line-height: 24px;
  padding: 4px 0 0;
  outline: none;

  color: $primary-dark-blue;

  &:focus, &:hover {
    text-decoration: underline;
    color: $primary-dark-blue;
  }

  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 6px;
    height: 12px;
    background-image:
            url("data:image/svg+xml;utf8,<svg width='6' height='12' viewBox='0 0 6 12' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0.5 11L5.5 6L0.5 1' stroke='%23004FC4' stroke-linecap='round' stroke-linejoin='round'/></svg>");
    background-size: contain;
    pointer-events: none;
    transform: translate(300%, -50%);
    transition: transform 300ms;
  }

  &:hover:after, &:focus:after {
    transform: translate(400%, -50%);
  }
}
