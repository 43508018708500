@import 'styles/global';

.wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-size: 18px;
  text-align: center;
  margin-bottom: 24px;
}

.description {
  font-size: 18px;
  text-align: center;
  margin-bottom: 24px;
  padding: 0 24px;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.buttonCancel {
  cursor: pointer;
  display: block;
  text-align: center;
  align-self: center;
  font-size: 16px;
  line-height: 24px;
  border-width: 0;
  border-radius: 5px;
  background: $primary-orange;
  color: $primary-white;
  padding: 12px 12px;
  text-transform: uppercase;
}

.buttonInstall {
  cursor: pointer;
  display: block;
  text-align: center;
  align-self: center;
  font-size: 16px;
  line-height: 24px;
  border-width: 0;
  border-radius: 5px;
  background: $primary-dark-blue;
  color: $primary-white;
  padding: 12px 12px;
  text-transform: uppercase;
  margin-left: 12px;
}