@import '../../styles/global';

$side-padding: 3rem;

.container {
  width: auto;
  height: auto;
  margin: 0 auto;
}

:global(.carousel.carousel-slider) {
  padding: 0 $side-padding 3rem $side-padding;
}

:global(.carousel) {
  :global(.control-dots) {
    // transform: translateY(1rem);
    max-width: calc(100% - #{$side-padding}*2);
    :global(.dot) {
      background: $primary-white;
      border: 1px solid $primary-dark-blue;
      box-shadow: none;
      width: 2rem;
      height: 2rem;
      opacity: 1;
      &:focus {
        outline: none;
      }
      &:global(.selected) {
        background: $primary-orange;
        border-color: $primary-orange;
      }
    }
  }
  :global(.carousel-status) {
    display: none;
  }
  :global(.slide) {
    background: none;
  }
}

.arrowPrev {
  z-index: 100;
  position: absolute;
  top: 50%;
  transform: translateY(-100%);
  left: 0;
  width: 0;
  height: 0;
  opacity: 0.5;
  border-right: 2rem solid black;
  border-top: 2rem solid transparent;
  border-bottom: 2rem solid transparent;
}

.arrowNext {
  z-index: 100;
  position: absolute;
  top: 50%;
  transform: translateY(-100%);
  right: 0;
  width: 0;
  height: 0;
  opacity: 0.5;
  border-left: 2rem solid black;
  border-top: 2rem solid transparent;
  border-bottom: 2rem solid transparent;
}

.active {
  opacity: 1;
}

p {
  margin: 0;
  font-size: 5rem;
}