@import 'styles/global';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.description {
  max-width: 650px;
  font-size: 18px;
  text-align: center;
  margin-bottom: 24px;
  padding: 0 24px;
}
