.heading {
  font-size: 36px;
  line-height: 120%;
  font-weight: normal;
  text-align: center;
  margin: 30px 2.5rem;
}

.secondary {
  text-align: left;
  font-size: 24px;
  line-height: 36px;
  text-decoration: underline;
}