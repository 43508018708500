
html, #root {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  user-select: none;
}

#portal-root {
  pointer-events: none;
  top: 0;
  left: 0;
  z-index: 100;
  overflow: hidden;
  position: absolute;
  height: 100%;
  width: 100vw;
}

*, *:before, *:after {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Montserrat", sans-serif;
    margin: 0;
}

h3, h4 {
    font-weight: 600;
}

h1 {
    font-size: 10.8rem;
    line-height: 9.7rem;
}
h2 {
    font-size: 7.2rem;
    line-height: 8.63rem;
}
h3 {
    font-size: 5.4rem;
    line-height: 6.47rem;
}
h4 {
    font-size: 4rem;
    line-height: 5.4rem;
}
h5 {
    font-size: 3.3rem;
    line-height: 5.28rem;
}
h6 {
    font-size: 2.7rem;
    line-height: 4.31rem;
}

@media screen and (max-device-width: 480px) {
  body{
    -webkit-text-size-adjust: 100%;
  }
}
