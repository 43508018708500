@import 'styles/global';

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.menu {
  position: absolute;
  top: 0;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $primary-white;
  padding: 72px 0;
  transform: translateY(-150%);
  transition: transform 500ms;
  &.open {
    transform: none;
  }
}


.tab {
  line-height: 52px;
  margin-right: 24px;
  font-size: Min(5em, 24px);
  transition: 0.25s;
  border-bottom: 2px solid transparent;
  border-bottom-color: transparent;
  color: $primary-dark-blue;
  cursor: pointer;

  &:hover {
    color: $primary-orange;
  }
}

.activeTab {
  border-bottom-color: currentColor;
}

.toggler {
  margin-right: 10px;
  z-index: 200;
  color: transparent;
  background: $primary-white;
  width: 52px;
  height: 52px;
  border: 1px solid $secondary-gray-4;
  border-radius: 16px;
  outline: none;
  background-repeat: no-repeat;
  background-size: 80% 80%;
  background-position: center bottom;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3Csvg xmlns:dc='http://purl.org/dc/elements/1.1/' xmlns:cc='http://creativecommons.org/ns%23' xmlns:rdf='http://www.w3.org/1999/02/22-rdf-syntax-ns%23' xmlns:svg='http://www.w3.org/2000/svg' xmlns='http://www.w3.org/2000/svg' xmlns:sodipodi='http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd' xmlns:inkscape='http://www.inkscape.org/namespaces/inkscape' width='18' height='18' viewBox='0 0 18 18' fill='none' version='1.1' id='svg6' sodipodi:docname='x-down.svg' inkscape:version='0.92.4 (5da689c313, 2019-01-14)'%3E%3Cmetadata id='metadata12'%3E%3Crdf:RDF%3E%3Ccc:Work rdf:about=''%3E%3Cdc:format%3Eimage/svg+xml%3C/dc:format%3E%3Cdc:type rdf:resource='http://purl.org/dc/dcmitype/StillImage' /%3E%3Cdc:title%3E%3C/dc:title%3E%3C/cc:Work%3E%3C/rdf:RDF%3E%3C/metadata%3E%3Cdefs id='defs10' /%3E%3Csodipodi:namedview pagecolor='%23ffffff' bordercolor='%23666666' borderopacity='1' objecttolerance='10' gridtolerance='10' guidetolerance='10' inkscape:pageopacity='0' inkscape:pageshadow='2' inkscape:window-width='3840' inkscape:window-height='1993' id='namedview8' showgrid='false' inkscape:zoom='74.167645' inkscape:cx='11.082176' inkscape:cy='10.60433' inkscape:window-x='0' inkscape:window-y='480' inkscape:window-maximized='1' inkscape:current-layer='svg6' /%3E%3Cpath d='M 13.5,4.5 9,9' id='path2' inkscape:connector-curvature='0' style='stroke:%23004fc4;stroke-linecap:round;stroke-linejoin:round' sodipodi:nodetypes='cc' /%3E%3Cpath d='M 4.5,4.5 9,9' id='path4' inkscape:connector-curvature='0' style='stroke:%23004fc4;stroke-linecap:round;stroke-linejoin:round' sodipodi:nodetypes='cc' /%3E%3C/svg%3E%0A");
  &.open {
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.5 4.5L4.5 13.5' stroke='%23004FC4' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4.5 4.5L13.5 13.5' stroke='%23004FC4' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: center center;
  }
}

.item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  padding-left: 72px;
  .label {
    display: block;
  }
}

.item.primary {
  font-weight: bold;
  font-size: 22px;
}

.item.secondary {
  font-size: 18px;
}

// .list {
//   list-style: none;
//   margin: 0;
//   padding: 0;
//   width: 250px;
//   height: 75px;

// }

// .item {
//   height: 75px;
//   background: $primary-white;
//   font-size: 20px;
//   line-height: 30px;
//   font-weight: bold;
//   color: $primary-black;
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;
//   transition: transform 250ms;
//   transform-origin: left top;

//   &:nth-child(2) {
//     transform: scaleY(0);
//     color: $primary-black;
//     .open & {
//       transform: scaleY(1);
//     }
//   }
// }

// .text {
//   display: block;
//   position: relative;
//   padding-right: 30px;

//   .item:first-child &:after {
//     display: block;
//     content: '';
//     position: absolute;
//     right: 0;
//     top: 0;
//     width: 30px;
//     height: 30px;
//     background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.5 5.25L7 8.75L10.5 5.25' stroke='%23004FC4' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
//     background-size: 100% 100%;
//     transition: transform 250ms;

//     .open & {
//       transform: rotate(90deg);
//     }
//   }
// }
