@import 'styles/global';

.wrapper {
  background: $primary-white;
  margin: 2.5rem;
  padding: 24px;
  border: 1px solid #C4C4C4;
  border-radius: 5px;
}

.groupName {
  font-size: 16px;
  line-height: 26px;
  font-weight: bold;
}

.items {

}

.item {
  font-size: 16px;
  line-height: 26px;
}

.linkWebsite {
  opacity: 0.7;
}

.link {
  color: $primary-dark-blue;
  text-decoration: none;

  &:hover, &:active, &:focus {
    color: $primary-orange;
  }
}
