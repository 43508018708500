@import "styles/global";

.wrapper {
  position: relative;
  z-index: 1000;
  min-height: 100%;
  background: $primary-white;
  padding: 36px;
  display: flex;
  flex-direction: column;

  .heading, h3 {
    max-width: 500px;
    margin: 0 auto;
    font-size: 36px;
    line-height: 52px;
    font-weight: bold;
    text-align: center;
  }

  h2 {
    max-width: 500px;
    margin: 22px auto 12px auto;
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
    text-align: center;
  }

  p {
    max-width: 500px;
    font-size: 18px;
    line-height: 32px;
    margin: 12px auto 22px auto;
  }

  ul {
    max-width: 500px;
    font-size: 18px;
    margin: 12px auto 22px auto;
  }
}

.homeLink {
  display: block;
  text-align: center;
  align-self: center;
  font-size: 18px;
  line-height: 32px;
  border: 1px solid $secondary-dark-blue;
  border-radius: 5px;
  background: $primary-dark-blue;
  color: $primary-white;
  padding: 12px;
  margin: 36px 0;
}
