.outerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  //position: fixed;

}

.innerContainer {
  width: 100%;
  height: 100%;
  position: relative;
}
