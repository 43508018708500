@import 'styles/global';

.options {
  @include reset-unordered-list;
  border: 1px solid $secondary-gray-4;
  border-radius: 5px;
  background: $primary-white;
  margin: 0 2.5rem;
  display: flex;

  flex-direction: column;

  @include for-desktop{
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.option {
  @include reset-unordered-list;
  display: block;
  position: relative;
  font-size: 16px;
  font-weight: bold;

  width: 100%;
  text-align: center;
  padding: 62px 0 16px 0;

  @include for-desktop {
    padding: 24px 0 24px 76px;
    width: 45%;
    text-align: left;
  }

  &:before {
    display: block;
    content: '';
    position: absolute;
    width: 48px;
    height: 48px;
    background-image: url("data:image/svg+xml,%3Csvg width='94' height='94' viewBox='0 0 94 94' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='47' width='66' height='66' rx='24' transform='rotate(-45 0 47)' fill='%2378A2FF'/%3E%3Cpath d='M55 42H39C37.8954 42 37 42.8954 37 44V54C37 55.1046 37.8954 56 39 56H55C56.1046 56 57 55.1046 57 54V44C57 42.8954 56.1046 42 55 42Z' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M51 42V40C51 39.4696 50.7893 38.9609 50.4142 38.5858C50.0391 38.2107 49.5304 38 49 38H45C44.4696 38 43.9609 38.2107 43.5858 38.5858C43.2107 38.9609 43 39.4696 43 40V42' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-size: contain;

    top: 8px;
    left: 50%;
    transform: translate(-50%, 0);

    @include for-desktop {
      top: 50%;
      left: 16px;
      transform: translate(0, -50%);
    }
  }

}