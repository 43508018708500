@import "styles/global";

.button {
  cursor: pointer;
  color: $primary-black;
  font-size: 14px;
  height: 42px;
  padding: 0 10px 0 40px;
  background: $primary-white;
  border: 1px solid $secondary-gray-4;
  border-radius: 10px;
  box-shadow: none;
  font-weight: 600;
  letter-spacing: 0.02em;
  display: inline-block;
  margin: 0;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  -webkit-tap-highlight-color: $secondary-dark-blue;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  transition: background 250ms, color 250ms, border-color 250ms;

  &.pdf:after {
    background-image: url("./pdf-icon.svg");
  }
  
  &.email:after {
    background-image: url("./email-icon.svg");
  }

  position: relative;
  &:after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    width: 25px;
    height: 25px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }

  transition: opacity 300ms;
  &:disabled {
    opacity: 0.5;
  }
}
