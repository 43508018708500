@import "styles/global";

.wrapper {
  @include for-desktop {
    max-width: 50rem;
  }
}

.title {
  font-size: 24px;
  line-height: 36px;
  white-space: pre;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 12px;
}

.text {
  font-size: 14px;
  line-height: 24px;
  margin: 12px 0;
  text-align: left;
  p {
    font-size: 14px;
    line-height: 24px;
    margin: 12px 0;
    text-align: left;
  }
  ul {
    list-style: none;
  }
  li {
    margin-bottom: 12px;
  }
  li::before {
    content: "\2022";
    color: $primary-dark-blue;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}