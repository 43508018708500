@import "styles/global";

.trial {
  display: flex;
  flex-direction: column;
  height: 100%;
  pointer-events: none;
  position: relative;
  &.selectable {
    pointer-events: unset;
  }

}

.explanation {
  position: absolute;
  left: 0;
  top: 50%;
  padding: 0 2.5rem;
  pointer-events: none;
  opacity: 0;

  & p {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }

  .trial.explanationVisible & {
    animation: fade-in 500ms 1s forwards;
  }

  @keyframes fade-in {
    from { opacity: 0 }
    to   { opacity: 1 }
  }
}

.gameArea {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 1s;
  transform-origin: top center;

  .trial.explanationVisible & {
    transform: scale(0.6);
  }
}

.patternImage {
  // background-color: yellow;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 90rem;
  height: 26rem;
  margin: 2.5rem 0;
}

.matches {
  // background: red;
  display: flex;
  justify-content: space-between;
  width: calc(5 * 13rem + 4 * 2.5rem);
  padding: 0.5rem;
  border: 0.3rem solid $secondary-gray-3;
}

.match {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 13rem;
  height: 13rem;
  position: relative;
  cursor: pointer;
  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $primary-green;
    opacity: 0;
    transition: opacity 200ms, background-color 200ms;
  }

  &.selected:after {
    opacity: 0.5;
  }

  &.selected.incorrect:after {
    background-color: $primary-red;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 2.5rem;
}

.buttonConfirm {
}

.buttonNext {
  background-color: $primary-dark-blue;
  color: $primary-white;
  position: absolute;
  right: 0;
}

.skipButton {
  left: 0;
}
