@import "styles/global";

.button {
  cursor: pointer;
  color: $primary-dark-blue;
  font-size: 14px;
  height: 42px;
  padding: 0 24px;
  background: $primary-white;
  border: 1px solid $secondary-gray-4;
  border-radius: 10px;
  box-shadow: none;
  font-weight: 600;
  letter-spacing: 0.02em;
  display: inline-block;
  margin: 0;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  -webkit-tap-highlight-color: $secondary-dark-blue;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  transition: background 250ms, color 250ms, border-color 250ms;

  &:hover {
    background: $secondary-blue;
    color: $primary-white;
    border-color: $secondary-gray-3;

  }

  &:focus {
    border-color: $secondary-dark-blue;
    border-width: 2px;
    padding: 0 23px;
  }
}
