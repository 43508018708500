@import "src/styles/global";

.container {
  padding: 5em;
  width: 100vw;
  height: 100%;
  margin: auto;
  text-align: center;

  .afterLoginImgContainer {
    width: 100%;
    height: 70%;
    overflow-y: hidden;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    @include custom-scrollbar;

    .afterLoginImg {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }

    @media (max-height: 480px) and (orientation: landscape) {
      height: 65%;
      width: 65%;
      overflow-y: auto;
      display: block;

      .afterLoginImg {
        max-width: unset;
        max-height: unset;
        width: 100%;
        height: auto;
      }
    }
  }

  .content {
    padding: 3rem;
    p {
      font-size: 20px;
    }

    @media (max-width: 768px) and (orientation: portrait) {
      p {
        font-size: 14px;
      }
    }

    @media (max-height: 768px) and (orientation: landscape) {
      p {
        font-size: 14px;
      }
    }
  }

  .startButton {
    cursor: pointer;
    display: block;
    text-align: center;
    align-self: center;
    font-size: 24px;
    line-height: 32px;
    border: 1px solid $secondary-dark-blue;
    border-radius: 5px;
    background: $primary-dark-blue;
    color: $primary-white;
    padding: 12px 24px;
    text-transform: uppercase;
    margin: auto;
  }
}
