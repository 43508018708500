@import "styles/global";

.card {
  border: 1px solid $secondary-gray-4;
  background: $primary-white;
  border-radius: 5px;
  padding: 24px;
  vertical-align: top;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;

  width: 75rem;
  margin: 1.25rem 2.5rem;

  @include for-desktop {
    width: 24rem;
  }
}

.name {
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 24px;
}

.items {
  text-align: start;
  list-style-type: disc;
}

.item {
  font-size: 16px;
  line-height: 26px;
}

.linkWebsite {
  opacity: 0.7;
}

.link {
  color: $primary-dark-blue;
  text-decoration: none;

  &:hover, &:active, &:focus {
    color: $primary-orange;
  }
}
