@import 'styles/global';

.container {
  margin: 2.5rem;
  background: $primary-white;
  box-shadow: 0px 0.3rem 1rem rgba(0, 0, 0, 0.08);
  padding: 24px;
  display: grid;

  grid-template-areas: 
  "infoCard"
  "heading"
  "text"
  "button";

  @include for-desktop {
    grid-template-columns: 1fr calc(24rem - 24px);
    grid-column-gap: 24px;
    grid-template-areas: 
      "heading infoCard"
      "text infoCard"
      "button infoCard";
  }

}

.infoCard {
  grid-area: infoCard;
  align-self: flex-start;
  border: 1px solid $secondary-gray-4;
  background: $primary-white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 50%;

  padding: 6px 12px;
  @include for-desktop {
    margin: 0;
    padding: 24px;
  }
}

.infoEntry {
  display: inline;

  font-size: 12px;
  line-height: 20px;
  @include for-desktop {
    font-size: 16px;
    line-height: 27px;
  }
}

.infoEntryKey {
  font-weight: bold;
}

.heading {
  grid-area: heading;
  font-size: 24px;
  line-height: 30px;

  margin-top: 12px;
  @include for-desktop {
    margin-top: 0;
    font-size: 24px;
    line-height: 36px;
  }
}

.text {
  grid-area: text;
  font-size: 14px;
  line-height: 24px;
  margin: 12px 0;
  @include for-desktop {
    margin: 24px 0;
  }
}

.button {
  grid-area: button;
}

.pdfButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pdfHeading {
  font-size: 18px;
  line-height: 30px;
  margin: 24px 0 12px 0;
}

.pdfDescription {
  font-size: 14px;
  margin: 12px 0;
}