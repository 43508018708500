@import "styles/global";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  .title {
    font-size: 3.5rem;
    &:after {
      display: block;
      content: '';
      width: 14rem;
      height: 100%;
      right: 0;
      background-image: url("./completed_k_b_stars.svg");
      background-size: 90%;
      background-position: bottom right;
      background-repeat: no-repeat;
    }
  }

  .message {
    width: 50rem;
    font-size: 2.75rem;
  }
}
