.image {
  max-width: 40vh;
}

.tablet {
  transition: transform 300ms;
  transform: translate(100%, 50%);
  .image:global(.stage-2) & {
    transform: none;
  }
}

.bubble {
  transition: transform 300ms;
  transform: translate(0, -100%);
  .image:global(.stage-3) & {
    transform: none;
  }
}

.newspaper {
  transition: transform 300ms;
  transform: translate(-100%, 50%);
  .image:global(.stage-1) & {
    transform: none;
  }
}

.guy {
  transition: opacity 300ms;
  opacity: 0;
  .image:global(.stage-6) & {
    opacity: 1;
  }
}

.magnifyingGlass {
  transition: transform 300ms;
  transform: translate(-100%, 50%);
  .image:global(.stage-4) & {
    transform: none;
  }
}

.dot1 {
  transition: transform 300ms;
  transform: translate(100%, 0);
  .image:global(.stage-6) & {
    transform: none;
  }
}

.dot2 {
  transition: transform 300ms 100ms;
  transform: translate(100%, 0);
  .image:global(.stage-6) & {
    transform: none;
  }
}

.dot3 {
  transition: transform 300ms 200ms;
  transform: translate(100%, 0);
  .image:global(.stage-6) & {
    transform: none;
  }
}
