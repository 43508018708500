
$hero-color: #353F97;
$primary-green: #05A660;
$primary-light-blue: #00B7C4;
$primary-dark-blue: #004FC4;
$primary-purple: #4C0099;
$primary-orange: #E57A00;
$primary-white: #FFFFFF;
$primary-black: #000000;
$primary-yellow: #E5B800;
$primary-red: #E53535;

$secondary-gray-1: #111111;
$secondary-gray-2: #222222;
$secondary-gray-3: #888888;
$secondary-gray-4: #C4C4C4;
$secondary-gray-5: #E0E0E0;
$secondary-gray-6: #F2F2F2;
$secondary-dark-blue: #003585;
$secondary-light-purple: #990099;
$secondary-dark-green: #048C51;
$secondary-blue: #78A2FF;

$secondary-blue: #78A2FF;
