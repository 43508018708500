@import "styles/global";

.wrapper {
  display: grid;
  grid-template-rows: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1.2fr);
  grid-template-areas:
    "text"
    "animation"
    "buttons";
}

@keyframes show-text {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.text {
  grid-area: text;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.line-1 {
  font-size: 18px;
  text-align: center;
  opacity: 0;
  animation: show-text 300ms 300ms forwards;
  white-space: nowrap;
}

.line-2 {
  font-size: 36px;
  line-height: 76px;
  font-weight: bold;
  text-align: center;
  opacity: 0;
  animation: show-text 300ms 600ms forwards;
}

.line-3 {
  font-size: 18px;
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
  opacity: 0;
  animation: show-text 300ms 900ms forwards;
}

.animation {
  grid-area: animation;
  height: 100%;
  display: flex;
  justify-content: center;
}

.buttons {
  grid-area: buttons;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  pointer-events: none;
  &.readyToGo {
    pointer-events: auto;
    .about {
      animation: show-text 300ms forwards;
    }
    .installButton {
      animation: show-text 300ms 200ms forwards;
    }
    .startButton {
      animation: show-text 300ms 200ms forwards;
    }
    .consent {
      animation: show-text 300ms 200ms forwards;
    }
    .dataUsage_text {
      animation: show-text 300ms 200ms forwards;
    }
    .privacyPolicy {
      animation: show-text 300ms 100ms forwards;
    }
  }
}

.about, .privacyPolicy {
  display: block;
  font-size: 18px;
  opacity: 0;
}

.startButton {
  cursor: pointer;
  display: block;
  text-align: center;
  align-self: center;
  font-size: 24px;
  line-height: 32px;
  border: 1px solid $secondary-dark-blue;
  border-radius: 5px;
  background: $primary-dark-blue;
  color: $primary-white;
  padding: 12px 24px;
  text-transform: uppercase;
  opacity: 0;

  &:disabled {
    background: $secondary-gray-3;
  }
}

.consent {
  opacity: 0;
  p {
    font-size: 14px;
  }
}

.consent_wrapper {
  display: flex;
  padding: 10px;
}

.dataUsage_text {
  opacity: 0;
  font-size: 13px;
  font-style: italic;
  padding: 5px 16px;
}

.consent_text {
  margin-left: 10px;
}
.consent_text_two {
  margin-left: 10px;
}

@mixin mobileStyles {
  .startButton {
    font-size: 20px;
    padding: 6px 20px;
  }

  .wrapper {
    grid-template-rows: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1.7fr);
  }

  .privacyPolicy {
    margin-bottom: 8px;
  }

  .buttons {
    gap: 8px 0;
  }
}

@media (max-width: 768px) and (orientation: portrait) {
  @include mobileStyles;
}

@media (max-height: 768px) and (orientation: landscape) {
  @include mobileStyles;
}

@media (max-width: 320px) and (orientation: portrait) {
  .wrapper {
    grid-template-rows: auto auto auto;
  }
}

@media (max-height: 480px) and (orientation: landscape) {
  .wrapper {
    grid-template-areas:
    "text text"
    "animation buttons"
    ". .";
    grid-template-rows: auto auto auto;
    gap: 8px 0;
    grid-template-columns: minmax(0, 0.4fr) minmax(0, 1fr);
  }

  .text {
    padding: 0 16px;
    flex-direction: row;
    align-items: center;
  }

  .line-2 {
    margin: 0 10px;
  }

  .line-3 {
    margin: 0;
  }
}
