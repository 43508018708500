@import "styles/global";

.wrapper {
  position: relative;

  &:after {
    display: none;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.75) 0rem, 
      rgba(255, 255, 255, 0) 2.5rem,
      rgba(255, 255, 255, 0) 97.5rem,
      rgba(255, 255, 255, 0.75) 100rem
      );
  }

  :global(.carousel.carousel-slider) {
    overflow: visible;
    padding: 0;
    margin: 0 auto;
  }

  
  :global(.carousel .control-dots) {
    position: relative;
    max-width: 100%;
    margin: 18px 0;
    @include for-desktop {
      margin: 32px 0;
    }
  }

  :global(.carousel .dot) {
    box-shadow: none;
    width: 18px;
    height: 18px;
    margin: 0 4px;
    border-radius: 50%;
    background: $secondary-blue;

    @include for-desktop {
      width: 32px;
      height: 32px;
    }

    &.selected {

    }
  }

}
