.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #333;
}

.getReadyText {
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 2.5rem;
  margin-bottom: 1rem;
}

.countdown {
  display: block;
  font-weight: normal;
  font-size: 2.5rem;
}
