@import "src/styles/global";

.container {
  background-color: #FFFFFF;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 4.5rem;
  position: relative;

  .gameArea {
    flex-basis: 65%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    align-items: center;
    position: relative;
  }

  .board {
    width: 38rem;
    height: 38rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;

    .square {
      width: 6.6rem;
      height: 6.6rem;
      background: $primary-dark-blue;
      border-radius: 1rem;
      transition: .3s ease-in-out;

      &.hidden {
        background-color: transparent;
      }

      &.bombExplode {
        background: transparent url("img/bomb.svg") no-repeat center;
        background-size: 90%;
      }

      &.bombNotExplode {
        background: $primary-dark-blue url("img/bomb.svg") no-repeat center;
        background-size: 90%;
      }
    }
  }

  div {
    text-align: center;
  }

  p {
    font-size: 2.1rem;
    line-height: 3.3rem;
  }

  .bold {
    font-weight: bold;
    font-size: 2.5rem;
  }

  .gameResults {
    height: 100%;
    flex-basis: 35%;
    //height: 35rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    gap: 2rem;
    position: relative;
  }

  .buttons {
    display: flex;
    justify-content: center;
  }
}

.bretBtn {
  margin: 0 2.3rem;

  &.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.3;

    &:hover, &:active, &:focus {
      box-shadow: none;
    }
  }
}

.feedbackBox {
  background-color: #FFFFFF;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: .3s ease-in-out;
  pointer-events: none;

  button {
    pointer-events: none;
  }

  .feedbackImg {
    width: 100%;
    height: 100%;
    background-color: #777777;

    &.coin {
      background: transparent url("img/coins.svg") no-repeat center;
      background-size: 50%;
    }

    &.explode {
      background: transparent url("img/explosion.svg") no-repeat center;
      background-size: 50%;
    }
  }
}

.modal {
  padding: 4.5rem;
  transition: opacity .1s ease-in-out;

  .bomb {
    width: 24.7rem;
    height: 24.7rem;
    background: url("img/bomb.svg") no-repeat center;
    background-size: cover;
  }

  p {
    margin-bottom: 5.5rem;
  }
}

.green {
  color: $primary-green;
}

.red {
  color: $primary-red;
}

.bombInfo {
  opacity: 0;
}

.visible {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;

  button {
    pointer-events: auto;
  }
}

.coinsSoFar {
  position: relative;
  font-size: 4rem;
  line-height: 4rem;
  width: 6rem;

  &:after {
    position: absolute;
    display: block;
    content: '';
    width: 3rem;
    height: 3rem;
    top: 100%;
    right: 0;
    transform: translate(100%, -100%);
    background: url("img/coin.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.skipButton {
  right: 4.5rem;
  bottom: 4.5rem;
}
