@import 'styles/global';

.Container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;

  .BlockEnd {
    width: 100%;
    height: 100%;
    background-color: transparent;
  }

  .LetterContainer {
    width: 40rem;
    height: 40rem;
    border-radius: $border-radius-percent;
    background-color: $hero-color;
    display: flex;
    justify-content: center;
    align-items: center;
    .Letter {
      font-size: 15rem;
      color: $primary-white;
    }
  }
}

.Overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  .ClickCapture {
    pointer-events: initial;
    width: 100%;
    height: 100%;
    background: transparent;
  }
  .LetterRest {
    width: 100%;
    height: 100%;
    background: transparent;
  }
  .Pause {
    width: 100%;
    height: 100%;
    background: transparent;
  }
}

.SkipButton {
  left: 3%;
  bottom: 3%;
}
