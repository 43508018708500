.item {
  margin-top: 48px;
}

.name {
  font-size: 16px;
  line-height: 24px;
}

.value {
  margin: 14px 0;
  font-size: 14px;
  line-height: 24px;
}
