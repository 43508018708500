@import "src/styles/global";

.scoreSelector {
  margin-top: 1rem;
  width: 100%;
  // height: 4rem;

  .range {
    width: 100%;
    height: 100%;
    margin: 0;
    -webkit-appearance: none;
    outline: none;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 3rem;
      width: 3rem;
      border-radius: 50%;
      background: $primary-dark-blue;
      // opacity: 0.5;
      cursor: pointer;
      margin-top: -0.7rem;
      transition: transform 300ms;
      filter: drop-shadow(0 0 0.25rem rgba(0,0,0,0.5));
  
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  
  .range::-moz-range-thumb {
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
  }

  .range::-webkit-slider-runnable-track {
    width: 100%;
    height: 1.5rem;
    cursor: pointer;
    background: linear-gradient(
      90deg,
      transparent 1%,
      $primary-dark-blue 1% 3%,
      $secondary-blue 3% 25%,
      $primary-dark-blue 25% 27%,
      $secondary-blue 27% 49%,
      $primary-dark-blue 49% 51%,
      $secondary-blue 51% 73%,
      $primary-dark-blue 73% 75%,
      $secondary-blue 75% 97%,
      $primary-dark-blue 97% 99%,
      transparent 99%
    );
  }
  
  .range:focus::-webkit-slider-runnable-track {
  }
  
  .range::-moz-range-track {
    width: 100%;
    height: 2rem;
    cursor: pointer;
    background: #3071a9;
  }

}

.labels {
  position: relative;
  height: 3.5rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.label {
  font-size: 1rem;
  width: 7rem;
  text-align: center;
  position: absolute;
  color: $secondary-gray-3;
  transition: color 300ms;

  &.selected {
    color: $primary-black;
  }

  &:nth-child(1) {
    left: -2rem;
    text-align: right;
  }

  &:nth-child(2) {
    left: 21.5rem;
  }

  &:nth-child(3) {
    left: 44rem;
  }

  &:nth-child(4) {
    left: 67rem;
  }

  &:nth-child(5) {
    left: 90.5rem;
    text-align: left;
  }
}