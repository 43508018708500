@import "src/styles/global";

.progressBar {
  position: fixed;
  width: 100vw;
  height: 0.5rem;
  top: 0;
  left: 0;
}

.progress {
  height: 100%;
  background: $primary-light-blue;
  transition: width .3s;
}
