// This will take the full "logical" screen area on mobile devices, including
// the area behind the browser's address bar.
.screenArea {
  width: 100%;
  height: 100%;
  position: relative;
}

// This will take only the screen area: between the bottom of the address bar
// and the bottom of the screen. This lets us determine the visible area, even
// if the mobile address bar is shown.
.visibleArea {
  position: fixed;
  width: 100%;
  height: 100%;
}

.content {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  min-height: 100%;
}
